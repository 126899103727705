<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Customervendor:Edit'">新增</el-button></div>
                <div class="h-b"><el-button type="primary" size="small" @click="enable" v-right-code="'Customervendor:Enable'">启用</el-button></div>
				<div class="h-b"><el-button type="primary" size="small" @click="disable" v-right-code="'Customervendor:Enable'">停用</el-button></div>
                <!-- <div class="h-b"><el-button type="primary" size="small" @click="del">删除</el-button> </div> -->
            </div>

            <p slot="elList">
                <el-table ref="sysConfigListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
									 :show-overflow-tooltip="col.FieldName=='ConfigMemo'"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='VendorCode'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on(this.config.moduleEvent.initial, () => this.reloadPageList());
            this.Event.$on(this.config.moduleEvent.add, () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/customervendor/getlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.$ajax.send("omsapi/customervendor/get", "get", {id:row.VendorId}, (data) => {
                    this.Event.$emit("clearEditSysConfigForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add() {
                this.$ajax.send("omsapi/customervendor/get", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            del() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择要删除的配置项.`, "error");
                    return false;
                }
                var This = this;
                this.Utils.confirm({
                    title: "是否确认删除配置项?",
                    content: "删除后数据不可恢复,该配置项将不可使用,是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/customervendor/delete", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("配置项删除成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消删除.", "info");
                });
            },
            enable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择启用的用户.`, "error");
                    return false;
                }
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "VendorId");
                this.$ajax.send("omsapi/customervendor/enable", "post", ids, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("用户启用成功.", "success");
                });
            },
            disable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择停用的用户.`, "error");
                    return false;
                }
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "VendorId");
                this.$ajax.send("omsapi/customervendor/enable", "post", ids, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("用户停用成功.", "success");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
        }
        //components: {
        //}
    }
</script>
<style>

</style>